import React from 'react';
import { graphql } from 'gatsby';

import Website from 'components/ui-setup';
import Layout from 'components/layout';
import TechProfessionals from 'components/tech-professionals';

type Props = {
  data: Object,
};

const TechProfessionalsPage = ({ data }: Props) => {
  const {
    techProfessionalsJson,
    currentOpeningsJson,
    logoJson,
    socialMediaJson,
    siteJson,
    cookieConsentJson,
  } = data;

  return (
    <Website
      content={{
        ...techProfessionalsJson,
        currentOpenings: currentOpeningsJson,
        cookieConsent: cookieConsentJson,
        menu: siteJson?.content,
        logo: logoJson,
        socialMedia: socialMediaJson,
      }}>
      <Layout>
        <TechProfessionals />
      </Layout>
    </Website>
  );
};

export default TechProfessionalsPage;

export const query = graphql`
  query TechProfessionalsPageQuery {
    techProfessionalsJson {
      hero {
        background {
          image
        }
        title
        text
      }
      unlock {
        title
        text
        buttonLabel
        buttonLink
        background
      }
      methodology {
        title
        buttonLabel
        buttonLink
        background
        methodologies {
          id
          title
          text
          image {
            xs
            sm
            md
            lg
            xl
          }
        }
      }
      practice {
        title
        cards {
          id
          title
          content
          image
          imageSize
        }
      }
      meta {
        description
        title
      }
    }
    currentOpeningsJson {
      background {
        image {
          xs
          sm
          md
          lg
          xl
        }
      }
      title
      text
      buttonLink
      buttonLabel
    }
    siteJson {
      content {
        whoWeAre {
          id
          title
          type
          position
          visible
          menuItems {
            ourLeadership {
              id
              link
              title
              type
              visible
            }
            vision {
              id
              link
              title
              type
              visible
            }
          }
        }
        whatWedo {
          id
          title
          type
          position
          visible
          menuItems {
            industries {
              id
              link
              title
              type
              visible
            }
            models {
              id
              link
              title
              type
              visible
            }
            practices {
              id
              link
              title
              type
              visible
            }
          }
        }
        techProfessionals {
          id
          link
          position
          title
          type
          visible
        }
        careers {
          id
          link
          position
          title
          type
          visible
        }
        blog {
          id
          link
          position
          title
          type
          visible
        }
        contact {
          id
          link
          position
          title
          type
          visible
        }
      }
    }
    cookieConsentJson {
      message
      buttons {
        accept
        reject
      }
    }
    logoJson {
      colorful
      white
    }
    socialMediaJson {
      label
      media {
        id
        image
        url
      }
    }
  }
`;
